//scss読み込み
import "../scss/style.scss";

//サポートするviewportの最小値を設定
(function () {
  const setSize = 360; //　希望のviewport下限を設定
  const viewport = document.querySelector("meta[name='viewport']");
  function adjustViewport() {
    /*
    三項演算子(条件演算子)
    condition ? exprIfTrue(trueに変換できる値) : exprIfFalse(falseに変換できる値)
    */
    const value = window.outerWidth > setSize ? "width=device-width,initial-scale=1" : "width=" + setSize;
    if (viewport.getAttribute("content") !== value) {
      viewport.setAttribute("content", value);
    }
  }
  addEventListener("resize", adjustViewport);
  adjustViewport();
})();

//js処理用メディアクエリ
let mediaFlg;
let width;
function media() {
  width = window.innerWidth;
  if (width > 1024) {
    mediaFlg = "pc";
  } else if (width >= 600) {
    mediaFlg = "tablet";
  } else if (width <= 599) {
    mediaFlg = "sp";
  }
}
window.addEventListener("load", function () {
  media();
});
window.addEventListener("resize", function () {
  media();
});
// 例 if (mediaFlg == "pc" || mediaFlg == "tablet") {~処理内容~}

//スクロールでアニメーションクラスを追加
if (document.querySelector(".anm") != null) {
  /*  .anmが付与されている要素全てをターゲットに設定  */
  const anm = document.querySelectorAll(".anm");

  /*  IntersectionObserverのオプションを設定可能(省略可能) */
  const options = {
    /*  交差を判定するトリガーを指定可能。指定しない場合はviewportがトリガーになる
        指定する場合は document.querySelector('.hoge') 等 */
    root: null,

    /*  交差位置 = 交差を監視している要素の座標 + ここに指定した数値(px % を指定可能)になる
        正数指定 = 要素の上辺が、指定した数値分画面に入る前に処理が開始
        負数指定 = 要素の上辺が、指定した数値分画面に入ってから処理が開始 */
    rootMargin: "-8%",

    /*  コールバック関数を実行する閾値を指定可能
        [0] = ターゲット要素が見え始め・見える終わりのタイミングで実行
        [0.5] = ターゲット要素の半分を通過したタイミングで実行
        [1] = ターゲット要素の下辺まで到達したタイミングで実行  */
    threshold: [0],
  };

  /*  IntersectionObserverを実行するためのインスタンスを生成
      (コールバック関数,オプション)の順で引数を記述(optionは省略可能)  */
  const observe = new IntersectionObserver(addAnimation, options);

  anm.forEach(function (elem, index) {
    /*  引数に記述した要素をターゲット要素としてIntersectionObserverを実行 */
    observe.observe(elem);
  });

  /*  IntersectionObserverのコールバック関数を設定
      引数(entries)に交差オブジェクトの配列が格納されている */
  function addAnimation(entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        /*  交差判定がtrueの場合  */
        entry.target.classList.add("is-show");
      }
    });
  }
}

// アコーディオンパネル
if (document.querySelector(".js-accordion") != null) {
  addEventListener("load", function () {
    const accordionList = document.querySelectorAll(".js-accordionList");
    const accordionListLength = accordionList.length;

    accordionList.forEach(function (elem, index) {
      const accordionTrigger = elem.querySelector(".js-accordionTrigger");
      const accordionContent = elem.querySelector(".js-accordionContent");
      const accordionContentH = accordionContent.offsetHeight; // それぞれのパネルの本来の高さを格納

      elem.classList.add("is-hide"); // 全トリガーの開閉アイコンを閉状態に設定
      elem.querySelector(".js-accordionContent").style.height = "0px"; // それぞれのパネルの高さを0にして閉状態に

      accordionTrigger.addEventListener("click", function (event) {
        // クリックされたトリガーが閉状態であれば
        if (elem.classList.contains("is-hide") === true) {
          for (let i = 0; i < accordionListLength; i++) {
            // 一旦全てのトリガーの選択状態をfalseに
            accordionList[i].querySelector(".js-accordionTrigger").setAttribute("aria-expanded", "false");
            // 一旦全てのパネルの非表示状態をfalseに
            accordionList[i].querySelector(".js-accordionContent").setAttribute("aria-hidden", "true");
            // 一旦全てのトリガーを閉状態に
            accordionList[i].classList.add("is-hide");
            // 一旦パネルの高さをautoから本来の高さに戻す(auto → 0 はtransitionしないため)
            accordionList[i].querySelector(".js-accordionContent").style.height = accordionContentH + "px";
            // リフローを発生させてパネルのheightがautoから本来の高さに変わったことをブラウザに描画させる
            document.defaultView.getComputedStyle(accordionList[i].querySelector(".js-accordionContent"), null).height;
            accordionList[i].querySelector(".js-accordionContent").style.height = 0 + "px";
          }
          // クリックされたトリガーの選択状態をtrueに
          accordionTrigger.setAttribute("aria-expanded", "true");

          //クリックされたトリガーに対応するパネルの非表示状態をtureに
          accordionContent.setAttribute("aria-hidden", "false");

          // クリックされたトリガーの閉状態を解除
          elem.classList.remove("is-hide");
          // クリックされたトリガーの直下パネルのみ一旦本来の高さに設定
          accordionContent.style.height = accordionContentH + "px";
          // クリックされたトリガーの直下パネルを取得
          const nextAccordionContent = event.target.closest(".c-accordion-list").querySelector(".p-accordion-list__content");
          // パネルが閉状態から開状態に変化し終わったら ↓ を実行
          nextAccordionContent.addEventListener("transitionend", function () {
            // 高さが0ではない(開状態の)パネルのみ、heightをautoにしてresizeに対応
            if (document.defaultView.getComputedStyle(nextAccordionContent, null).height !== "0px") {
              nextAccordionContent.style.height = "auto";
            }
          });
        } else {
          // クリックされたトリガーの選択状態をfalseに
          accordionTrigger.setAttribute("aria-expanded", "false");
          //クリックされたトリガーに対応するパネルの非表示状態をtrueに
          accordionContent.setAttribute("aria-hidden", "true");
          // クリックされたトリガーが開状態であれば
          for (let i = 0; i < accordionListLength; i++) {
            // 一旦パネルの高さをautoから本来の高さに戻す(auto → 0 はtransitionしないため)
            accordionList[i].querySelector(".js-accordionContent").style.height = accordionContentH + "px";
            // リフローを発生させてパネルのheightがautoから本来の高さに変わったことをブラウザに描画させる
            document.defaultView.getComputedStyle(accordionList[i].querySelector(".js-accordionContent"), null).height;
            // パネルを本来の高さから0までアニメーション
            accordionList[i].querySelector(".js-accordionContent").style.height = 0 + "px";
            // 全てのトリガーのアイコンを閉状態に更新
            accordionList[i].classList.add("is-hide");
          }
        }
      });
    });
  });
  // tabキーでfocusされたとき、enterでパネルを開けるように
  function handleKeyDown(event) {
    // 押されたキーの種類を判別
    if (event.code === "Enter") {
      document.activeElement.click();
    }
  }

  addEventListener("keydown", handleKeyDown);
}

//ハンバーガーメニュー
if (document.querySelector(".js-globalMenuButton") != null) {
  const globalMenu = document.querySelector(".js-globalMenuButton");
  const globalMenuInner = document.querySelector(".js-globalmenuInner");
  const globalMenuInnerList = document.querySelector(".js-globalmenuNavigation");
  const globalMenuAnchor = document.querySelectorAll(".js-globalmenuAnchor");
  const bars = document.querySelectorAll(".js-bar1,.js-bar2,.js-bar3");
  const body = document.body;
  let scrollTop;
  let scrollTopBack;
  globalMenu.addEventListener("click", function () {
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (globalMenu.getAttribute("aria-label") === "サイトマップを開く") {
      globalMenu.setAttribute("aria-label", "サイトマップを閉じる");
      globalMenu.setAttribute("aria-expanded", "true");
      globalMenuInner.setAttribute("aria-hidden", "false");
      async function addBgElm() {
        body.insertAdjacentHTML("afterbegin", '<div class="p-globalmenu-background js-globalmenuBackground"></div>');
      }
      async function addBgClass() {
        await addBgElm();
        const globalMenuBg = document.querySelector(".js-globalmenuBackground");
        setTimeout(function () {
          globalMenuBg.classList.add("is-active");
        }, 1);
      }
      addBgClass();
    } else {
      const globalMenuBg = document.querySelector(".js-globalmenuBackground");
      globalMenu.setAttribute("aria-expanded", "false");
      globalMenu.setAttribute("aria-label", "サイトマップを開く");
      globalMenuInner.setAttribute("aria-hidden", "true");
      globalMenuBg.classList.remove("is-active");
      globalMenuBg.addEventListener("transitionend", function () {
        globalMenuBg.remove();
      });
    }
    // tabでフォーカス切り替え可能に
    globalMenuAnchor.forEach(function (elem, index) {
      if (elem.getAttribute("tabindex") === "-1") {
        elem.removeAttribute("tabindex");
      } else {
        elem.setAttribute("tabindex", "-1");
      }
      // フォーカスをメニュー内に束縛
      elem.addEventListener("focus", function () {
        if (elem.classList.contains("js-focusReset")) {
          globalMenu.focus();
        }
      });
    });
    //アニメーション
    globalMenuInner.classList.toggle("is-show");
    globalMenuInnerList.classList.toggle("on-animation");
    bars.forEach(function (elem, index) {
      elem.classList.toggle("is-open");
    });
    // メニューオープン中はスクロール禁止 + クローズ時スクロール可能に戻す
    if (!body.classList.contains("is-fixed")) {
      body.classList.add("is-fixed");
      body.style.top = -scrollTop + "px";
      scrollTopBack = scrollTop;
    } else {
      body.classList.remove("is-fixed");
      window.scrollTo(0, scrollTopBack);
    }
  });
  // 背景をクリックした場合
  document.addEventListener("click", function (event) {
    if (event.target && event.target.classList.contains("js-globalmenuBackground")) {
      const globalMenuBg = document.querySelector(".js-globalmenuBackground");
      globalMenuInner.classList.toggle("is-show");
      bars.forEach(function (elem, index) {
        elem.classList.toggle("is-open");
      });
      globalMenuInnerList.classList.toggle("on-animation");
      globalMenu.setAttribute("aria-expanded", "false");
      globalMenu.setAttribute("aria-label", "サイトマップを開く");
      globalMenuInner.setAttribute("aria-hidden", "true");
      globalMenuBg.classList.remove("is-active");
      globalMenuBg.addEventListener("transitionend", function () {
        globalMenuBg.remove();
      });
      if (!body.classList.contains("is-fixed")) {
        body.classList.add("is-fixed");
        body.style.top = -scrollTop + "px";
        scrollTopBack = scrollTop;
      } else {
        body.classList.remove("is-fixed");
        window.scrollTo(0, scrollTopBack);
      }
    }
  });
}

//スムーススクロール
if (document.querySelector(".scrollTrigger") != null) {
  const scrollTrigger = document.querySelectorAll(".scrollTrigger");
  scrollTrigger.forEach(function (elem, index) {
    elem.addEventListener("click", function (event) {
      event.preventDefault();
      const scrollTargetAttr = elem.getAttribute("href");
      const scrollTargetElm = document.querySelector(scrollTargetAttr);
      const rect = scrollTargetElm.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollTargetTop = rect.top + scrollTop;
      window.scrollTo({
        top: scrollTargetTop,
        left: 0,
        behavior: "smooth",
      });
    });
  });
}

// header
const fixedHeader = document.querySelector(".js-fixedHeader");
const hideHeaderPos = 200; // どこまでスクロールしたらheaderを隠すか
let scrollAmount = 0; // スクロール量監視を初期化

function showToggle(posY) {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  posY = scrollTop;
  if (scrollAmount < posY && scrollAmount > hideHeaderPos) {
    fixedHeader.classList.add("is-hidden");
  } else {
    fixedHeader.classList.remove("is-hidden");
  }
  // if文を処理してからposYを呼び出し元に返すため、下降中はscrollTop > scrollAmount　上昇中はscrollTop < scrollAmount
  return posY;
}

window.addEventListener("scroll", function () {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  scrollAmount = showToggle(scrollAmount);
  if (scrollTop > hideHeaderPos + 300) {
    fixedHeader.classList.add("is-addbg");
  } else {
    fixedHeader.classList.remove("is-addbg");
  }
});

// header navigation
addEventListener("load", function () {
  const headerEach = document.querySelectorAll(".js-headerEach");
  headerEach.forEach(function (elem, index) {
    elem.classList.add("is-active");
  });
});

// fv bg
const parallaxBg = document.querySelector(".js-parallax-bg");
if (parallaxBg !== null) {
  parallaxFunc(parallaxBg);
}
function parallaxFunc(parallaxBg) {
  const rect = parallaxBg.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const targetPos1 = rect.top + scrollTop;
  const scrollYStart = targetPos1 - window.outerHeight;

  window.addEventListener("scroll", function () {
    let scrollY = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollY > scrollYStart) {
      if (mediaFlg == "pc" || mediaFlg == "tablet") {
        parallaxBg.style.backgroundPositionY = -(scrollY - targetPos1) * 0.15 + "px";
      } else if (mediaFlg == "sp") {
        parallaxBg.style.backgroundPositionY = -(scrollY - targetPos1) * 0.6 + "px";
      }
    }
    let nowPos = document.defaultView.getComputedStyle(parallaxBg, null).backgroundPositionY;
    nowPos = parseFloat(nowPos);
    if (nowPos > 0) {
      parallaxBg.style.backgroundPositionY = 0;
    }
  });
}

// fv text
addEventListener("load", function () {
  const fvText = document.querySelectorAll(".js-fvText");
  const fvBar = document.querySelector(".js-fvBar");
  if (fvText.length !== 0) {
    fvText.forEach(function (elem, index) {
      elem.classList.add("is-active");
    });
    fvBar.classList.add("is-active");
  }
});

// Topへ戻る
const toTop = document.querySelector(".js-toTop");
toTop.addEventListener("click", function () {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

// サブページはヘッダー色を黒にしたい
const subpage = document.querySelector(".subpage");
if (subpage !== null) {
  headerColorModify(subpage);
}

function headerColorModify(subpage) {
  subpage.querySelectorAll("header .color-wt").forEach((elm) => {
    elm.classList.remove("color-wt");
    elm.classList.add("color-bk");
  });
  document.querySelector(".p-header-imagebox__image").classList.add("p-header-imagebox__image--invert");
}

// ローディング画面
const loadingArea = document.querySelector(".js-loading"); // ローディング画面全体
if (loadingArea !== null) {
  loadingFunc(loadingArea);
}
function loadingFunc(loadingArea) {
  const images = document.querySelectorAll("img"); // ページ内の画像を全て取得
  const percentNumber = document.querySelector(".js-loadingPercentNumber"); // loadingと連動するパーセント部分
  const loadingGauge = document.querySelector(".js-loadingMeter"); // loadingと連動するメーター部分
  let imgCounting = 0;
  let baseCounting = 0;
  const gaugeWidth = 100; // ゲージの全体幅
  let current;

  // 画像の読み込み
  for (let i = 0; i < images.length; i++) {
    const img = new Image(); // 新たなimg要素を作成
    // 画像読み込み完了したとき
    img.addEventListener("load", function () {
      imgCounting += 1;
    });
    // 画像読み込み失敗したとき
    img.addEventListener("error", function () {
      imgCounting += 1;
    });
    img.src = images[i].src; // ソースのパスを設定
  }

  // 一定時間ごとにローディング演出を繰り返す
  const nowLoading = setInterval(function () {
    if (baseCounting <= imgCounting) {
      // ローディング状況をパーセンテージで算出
      current = Math.floor((baseCounting / images.length) * 100);
      // 算出したパーセンテージをHTMLに出力
      percentNumber.innerHTML = current;
      // 増加したパーセンテージをローディングゲージのwidthに更新
      loadingGauge.style.width = Math.floor((gaugeWidth / 100) * current) + "%";
      baseCounting += 1;

      // ローディングが完了した場合
      if (baseCounting === images.length) {
        // ローディング画面全体を非表示
        loadingArea.classList.add("is-loaded");
        // ローディング画面のフェードアウト演出が完了したらDOMツリーから取り除く
        loadingArea.addEventListener("transitionend", function () {
          clearInterval(nowLoading);
          loadingArea.remove();
        });
      }
    }
  }, 50);
}

// float
const showToggleElem = document.querySelector(".js-toggleContent"); // 表示を切り替えるコンテンツ
if (showToggleElem !== null) {
  toggleFunc(showToggleElem);
}
function toggleFunc(showToggleElem) {
  addEventListener("scroll", function () {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > 500) {
      showToggleElem.classList.add("is-show");
    } else {
      showToggleElem.classList.remove("is-show");
    }
  });
}

// 会社概要tab切り替え
const tabButtons = document.querySelectorAll(".js-tab-buttons button");
tabButtons.forEach((elms) => {
  elms.addEventListener("click", function (e) {
    const active = "is-active";
    tabButtons.forEach((buttons) => {
      buttons.classList.remove(active);
    });
    document.querySelectorAll(".company-tab-each").forEach((tabCont) => {
      tabCont.classList.remove(active);
    });
    e.target.classList.add(active);
    const classes = e.target.classList;
    for (let i = 0; i < classes.length; i++) {
      let targetClass = classes[i];

      const check = targetClass.split("trigger");
      if (targetClass === "trigger" + check[1]) {
        console.log(targetClass);
        document.querySelector(".tab" + check[1]).classList.add(active);
        return true;
      }
    }
  });
});
